import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {
    CSS3DRenderer,
    CSS3DObject
} from 'three/examples/jsm/renderers/CSS3DRenderer';

export class Scene {
    private container!: HTMLElement;
    private renderer = new THREE.WebGLRenderer({ antialias: true });
    private cssRenderer = new CSS3DRenderer();
    private controls!: OrbitControls;
    private camera!: THREE.PerspectiveCamera;
    private scene = new THREE.Scene();
    private k27: THREE.Object3D | undefined;
    private speakers: THREE.Object3D | undefined;
    private css3DFrames: CSS3DObject[] = [];

    constructor(container: HTMLElement) {
        this.container = container;

        this.initializeRenderer();
        this.container.appendChild(this.renderer.domElement);

        this.initializeCamera();
        this.initializeControls();

        this.addLights();
        this.loadModel();
        this.loadWebViews(
            'https://acrelecbenelux.com/order-demo',
            'https://www.radiozenders.fm/'
        );
        this.animate();
    }

    private initializeRenderer(): void {
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
        this.renderer.toneMappingExposure = 3.5;
        this.renderer.setClearColor(0xcccccc);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(window.innerWidth, window.innerHeight);
    }

    private initializeCamera(): void {
        this.camera = new THREE.PerspectiveCamera(
            50,
            window.innerWidth / window.innerHeight,
            0.1,
            1000
        );
        this.camera.position.z = 9;
    }

    private initializeControls(): void {
        this.controls = new OrbitControls(
            this.camera,
            this.renderer.domElement
        );
    }

    private addLights(): void {
        const ambientLight = new THREE.AmbientLight(0x6b6b6b, 1);
        this.scene.add(ambientLight);

        const pointLight = new THREE.PointLight(0xffffff, 1.2, 100, 0.2);
        const pointLightPositions = [
            new THREE.Vector3(20, 2, 1),
            new THREE.Vector3(-20, 2, -1),
            new THREE.Vector3(1, 2, 20),
            new THREE.Vector3(-2, 2, -20)
        ];

        pointLightPositions.forEach(position => {
            const light = pointLight.clone();
            light.position.copy(position);
            this.scene.add(light);
        });
    }

    private async loadModel(): Promise<void> {
        const loader = new GLTFLoader();
        try {
            const modelPath = process.env.PUBLIC_URL + '/k27s.glb';
            const gltf = await loader.loadAsync(modelPath);
            gltf.scene.translateY(-3.2);
            gltf.scene.rotateY(Math.PI);
            this.scene.add(gltf.scene);
            if (gltf.cameras?.length) {
                this.camera = gltf.cameras[0] as THREE.PerspectiveCamera;
                this.controls = new OrbitControls(
                    this.camera,
                    this.renderer.domElement
                );
            }

            this.k27 = gltf.scene.getObjectByName('K27');
            this.k27?.rotateY(Math.PI);

            this.speakers = gltf.scene.getObjectByName('Speakers');
            if (this.speakers) {
                this.fadeOut(this.speakers, 0);
            }

            this.updateCameraAspect();
            this.controls.update();
        } catch (error) {
            console.error('Error loading the model:', error);
        }
    }

    private animate = (): void => {
        requestAnimationFrame(this.animate);
        this.controls.update();
        TWEEN.update();
        this.css3DFrames.forEach(css3DFrame => {
            this.updateIframeVisibility(this.camera, css3DFrame);
        });
        if (this.speakers) {
            this.updateSpeakerVisibility(this.camera, this.speakers);
        }
        this.cssRenderer.render(this.scene, this.camera);
        this.renderer.render(this.scene, this.camera);
    };

    public fadeOut(object: THREE.Object3D, duration: number = 1000): void {
        object.traverse(child => {
            if (
                'isMesh' in child &&
                child.isMesh &&
                'material' in child &&
                child.material
            ) {
                let materials = Array.isArray(child.material)
                    ? child.material
                    : [child.material];
                materials.forEach(material => {
                    new TWEEN.Tween(material)
                        .to({ opacity: 0 }, duration)
                        .onUpdate(() => {
                            material.transparent = true;
                        })
                        .start();
                });
            }
        });
    }

    public fadeIn(object: THREE.Object3D, duration: number = 1000): void {
        object.traverse(child => {
            if (
                'isMesh' in child &&
                child.isMesh &&
                'material' in child &&
                child.material
            ) {
                const materials = Array.isArray(child.material)
                    ? child.material
                    : [child.material];
                materials.forEach(material => {
                    new TWEEN.Tween(material)
                        .to({ opacity: 1 }, duration)
                        .onUpdate(() => {
                            material.transparent = true;
                        })
                        .start();
                });
            }
        });
    }

    public fadeOutCSS3DObjects(duration: number = 1000): void {
        this.css3DFrames.forEach(css3DFrame => {
            new TWEEN.Tween(css3DFrame.element.style)
                .to({ opacity: 0 }, duration)
                .start();
        });
    }

    public fadeInCSS3DObjects(duration: number = 1000): void {
        this.css3DFrames.forEach(css3DFrame => {
            new TWEEN.Tween(css3DFrame.element.style)
                .to({ opacity: 1 }, duration)
                .start();
        });
    }

    private initializeCSS3DRenderer(): void {
        this.cssRenderer.setSize(window.innerWidth, window.innerHeight);
        document.body.appendChild(this.cssRenderer.domElement);
    }

    private loadWebViews(urlFront?: string, urlBack?: string): void {
        this.initializeCSS3DRenderer();

        const createIframe = (url: string): CSS3DObject => {
            const iframe = document.createElement('iframe');
            iframe.style.width = '1080px';
            iframe.style.height = '1920px';
            iframe.style.position = 'absolute';
            iframe.style.top = '0px';
            iframe.style.left = '0px';
            iframe.style.border = '0px';
            iframe.style.backfaceVisibility = 'hidden';

            iframe.src = url;

            return new CSS3DObject(iframe);
        };

        if (urlFront) {
            const iframeFront = createIframe(urlFront);
            this.css3DFrames.push(iframeFront);

            iframeFront.position.set(0, 0.24, 0.2307);
            iframeFront.scale.set(0.0009, 0.0009, 0.0009);

            this.scene.add(iframeFront);
        }

        if (urlBack) {
            const iframeBack = createIframe(urlBack);
            this.css3DFrames.push(iframeBack);

            iframeBack.position.set(0, 0.24, -0.2307);
            iframeBack.scale.set(0.0009, 0.0009, 0.0009);
            iframeBack.rotation.y = Math.PI;

            this.scene.add(iframeBack);
        }

        this.fadeOutCSS3DObjects(0);
        setTimeout(() => {
            this.fadeInCSS3DObjects(400);
        }, 200);
    }

    public updateCameraAspect(): void {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
    }

    public onWindowResize = (): void => {
        this.updateCameraAspect();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.controls.update();
        this.cssRenderer.setSize(window.innerWidth, window.innerHeight);
    };

    private updateIframeVisibility(
        camera: THREE.Camera,
        cssObject: CSS3DObject
    ): void {
        const frontVector = new THREE.Vector3(0, 0, -1);
        frontVector.applyQuaternion(cssObject.quaternion);

        const toCameraVector = new THREE.Vector3().subVectors(
            camera.position,
            cssObject.position
        );

        const angle = frontVector.angleTo(toCameraVector);

        if (angle <= Math.PI / 2) {
            cssObject.element.style.visibility = 'hidden';
        } else {
            cssObject.element.style.visibility = 'visible';
        }
    }

    private updateSpeakerVisibility(
        camera: THREE.Camera,
        speakers: THREE.Object3D
    ): void {
        const frontVector = new THREE.Vector3(0, 0, -1);
        frontVector.applyQuaternion(speakers.quaternion);

        const toCameraVector = new THREE.Vector3().subVectors(
            camera.position,
            speakers.position
        );

        const angle = frontVector.angleTo(toCameraVector);

        if (angle <= Math.PI / 2) {
            this.fadeIn(speakers, 500);
        } else {
            this.fadeOut(speakers, 100);
        }
    }

    public dispose(): void {
        this.scene.traverse(object => {
            if (object instanceof THREE.Mesh) {
                object.geometry.dispose();

                const materials = Array.isArray(object.material)
                    ? object.material
                    : [object.material];

                materials.forEach(material => {
                    if (material.map) {
                        material.map.dispose();
                    }
                    material.dispose();
                });
            }
        });

        while (this.container.firstChild) {
            this.container.removeChild(this.container.firstChild);
        }

        this.css3DFrames.forEach(css3DFrame => {
            if (
                css3DFrame.element instanceof HTMLElement &&
                css3DFrame.element.parentNode
            ) {
                css3DFrame.element.parentNode.removeChild(css3DFrame.element);
            }
        });

        if (this.cssRenderer.domElement.parentNode) {
            this.cssRenderer.domElement.parentNode.removeChild(
                this.cssRenderer.domElement
            );
        }

        this.renderer.domElement.parentElement?.remove();

        this.renderer.dispose();
        this.controls.dispose();

        this.css3DFrames = [];
    }
}
