import React, { useRef, useEffect } from 'react';
import './ARScene.css';
import { Scene } from './scene';

const ThreeScene: React.FC = () => {
  const mountRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mountRef.current) {
      return;
    }

    const scene = new Scene(mountRef.current);

    const onWindowResize = () => {
      scene.updateCameraAspect();
    };

    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
      scene.dispose();
    };
  }, []);

  return <div className="scene" ref={mountRef} />;
};

export default ThreeScene;
